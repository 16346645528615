import { useQueryClient } from "react-query";

const useRefetchQueries = () => {
  const queryClient = useQueryClient();
  // refetch all active queries partially matching a query key:
  const listKey = ["taskActivities/pagination", "connectFinanceSetting"];
  const refetchQueries = async () => {
    //list task activity on detail task
    await queryClient.refetchQueries(listKey, {
      active: true,
    });
  };

  return { refetchQueries };
};

export default useRefetchQueries;
