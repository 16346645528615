import { useSelector } from "react-redux";
import _ from "lodash";
import {
  configSelector,
  appSelector,
  selectedCompanySelector,
  titleSelector,
  referenceSelector,
  permissionSelector,
  settingSelector,
  dashboardSelector,
  billingSelector,
  isMobileViewSelector,
  isTabViewSelector,
  userSelector,
  optionsSelector,
  loadedInitSelector,
  isDesktopViewSelector,
  announcementSelector,
} from "redux/selectors";
import { findHrCompanies } from "utils/helper";

export const useConfigSelector = () => {
  const config = useSelector(configSelector);
  return config;
};

export const useAppSelector = () => {
  const app = useSelector(appSelector);
  const selectedCompany = useSelector(selectedCompanySelector);

  return { ...app, selectedCompany };
};

export const useReferenceSelector = () => {
  return useSelector(referenceSelector);
};

export const useUserSelector = () => {
  const user = useSelector(userSelector);
  return user;
};

export const useSettingSelector = () => {
  const setting = useSelector(settingSelector);
  return setting;
};

export const useAnnouncementSelector = () => {
  const announcement = useSelector(announcementSelector);
  return announcement;
};

export const useOptionSelector = () => {
  const option = useSelector(optionsSelector);
  return option;
};

export const useDashboardSelector = () => {
  const dashboard = useSelector(dashboardSelector);
  return dashboard;
};

export const useBillingSelector = () => {
  const billing = useSelector(billingSelector);
  return billing;
};

export const useTitleSelector = (name) => {
  const title = useSelector(titleSelector);
  return title ? title[name] : "";
};

export const useLoadedInitSelector = () => {
  const loaded = useSelector(loadedInitSelector);
  return loaded;
};

export const useMobileViewSelector = () => {
  const isMobileView = useSelector(isMobileViewSelector);
  return isMobileView;
};

export const useTabViewSelector = () => {
  const isTabView = useSelector(isTabViewSelector);
  return isTabView;
};

export const useDesktopViewSelector = () => {
  const isDesktopView = useSelector(isDesktopViewSelector);
  return isDesktopView;
};

export const useHrCompanieSelector = () => {
  const { companies, selectedCompany } = useAppSelector();
  if (!Array.isArray(companies)) {
    return { companies: [], selectedCompany };
  }
  const filteredCompanies = findHrCompanies(companies);
  return { companies: filteredCompanies, selectedCompany };
};

export const useIsOnlyHasRoleEmployee = () => {
  const user = useSelector(userSelector);
  return user?.is_user_has_only_employee_role_on_web;
};

export const usePermissions = (name, childName) => {
  const permissions = useSelector(permissionSelector);
  const findData = permissions.find((row) => row.name === name);
  const actions = {
    isCanAdd: false,
    isCanEdit: false,
    isCanDelete: false,
  };
  //childName untuk menu yang memiliki sub menu lagi
  if (childName) {
    if (findData && findData.child) {
      const findChildren = findData.child.find((row) => row.name === childName);
      if (findChildren && findChildren?.child) {
        (findChildren.child || []).forEach((row) => {
          if (row.name === `${childName}_add`) {
            actions.isCanAdd = true;
          } else if (row.name === `${childName}_edit`) {
            actions.isCanEdit = true;
          } else if (row.name === `${childName}_delete`) {
            actions.isCanDelete = true;
          } else {
            actions[row.name] = true;
          }
        });
      }
    }
  } else {
    if (findData && findData.child) {
      (findData.child || []).forEach((row) => {
        if (row.name === `${name}_add`) {
          actions.isCanAdd = true;
        } else if (row.name === `${name}_edit`) {
          actions.isCanEdit = true;
        } else if (row.name === `${name}_delete`) {
          actions.isCanDelete = true;
        } else {
          actions[row.name] = true;
        }
      });
    }
  }
  return actions;
};

export const usePermissionsMenu = (name) => {
  const permissions = useSelector(permissionSelector);
  let result = null;
  function traverse(node) {
    if (node?.name === name) {
      result = node;
      return;
    }
    //if child or sub permission exist
    if (!_.isEmpty(node?.child)) {
      node?.child.forEach((childNode) => traverse(childNode));
    }
  }
  if (!_.isEmpty(permissions) && Array.isArray(permissions)) {
    permissions.forEach((permission) => traverse(permission));
  }
  return result;
};

export const usePermissionsSetting = (name, childName) => {
  let nameSetting = name;
  const permissions = useSelector(permissionSelector);
  const findData = permissions.find((row) => row.name === "setting");
  if (findData) {
    const findSetting = (findData?.child || []).find(
      (row) => row.name === name
    );
    if (findSetting) {
      const actions = {
        isCanAdd: false,
        isCanEdit: false,
        isCanDelete: false,
      };
      if (childName) {
        if (findSetting && !_.isEmpty(findSetting?.child)) {
          const findSettingChildren = findSetting.child.find(
            (row) => row.name === childName
          );
          if (findSettingChildren && !_.isEmpty(findSettingChildren?.child)) {
            (findSettingChildren.child || []).forEach((row) => {
              if (row.name === `${childName}_add`) {
                actions.isCanAdd = true;
              } else if (row.name === `${childName}_edit`) {
                actions.isCanEdit = true;
              } else if (row.name === `${childName}_delete`) {
                actions.isCanDelete = true;
              } else {
                actions[row.name] = true;
              }
            });
          }
          return { ...findSettingChildren, ...actions };
        }
      } else {
        if (findSetting && findSetting.child && !_.isEmpty(findSetting.child)) {
          if (name === "setting_users") {
            nameSetting = "setting_user";
          }
          (findSetting.child || []).forEach((row) => {
            if (row.name === `${nameSetting}_add`) {
              actions.isCanAdd = true;
            } else if (row.name === `${nameSetting}_edit`) {
              actions.isCanEdit = true;
            } else if (row.name === `${nameSetting}_delete`) {
              actions.isCanDelete = true;
            } else {
              actions[row.name] = true;
            }
          });
        }
        return { ...findSetting, ...actions };
      }
    }
    return null;
  }
  return null;
};

export const useIsSettingExist = (name) => {
  const { menu } = useAppSelector();

  const settings = menu.find((e) => e?.name === "settings");
  if (settings && settings?.children) {
    const isSettingExist = (settings?.children || []).find(
      (e) => e?.name === name
    );
    return !_.isEmpty(isSettingExist);
  }
  return false;
};

export const useMenuDashboard = (parent, children) => {
  const { menu } = useAppSelector();

  const parentMenu = menu.find((e) => e?.name === parent);
  if (!_.isEmpty(parentMenu?.sub)) {
    const subMenu = (parentMenu?.sub || []).find((e) => e?.name === children);
    return subMenu;
  }
  return parentMenu;
};
