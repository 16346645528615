import { useMemo } from "react";
import _ from "lodash";

import { useUserSelector } from "./useSelector";

const useInitEmployee = () => {
  const user = useUserSelector();

  const isHasOnlyRoleEmployee = useMemo(() => {
    const roles = user?.app_roles;
    if (Array.isArray(roles) && roles.length === 1) {
      return roles.includes("employee");
    }
    return false;
  }, [user]);

  const employee = useMemo(() => {
    if (!_.isEmpty(user?.employee)) {
      return user.employee;
    }
    return {};
  }, [user]);

  return { isHasOnlyRoleEmployee, employee };
};

export default useInitEmployee;
